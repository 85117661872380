import request from "@/api/service";

const api = {
   page: '/order/mall/getUsePage',
   writeOff: 'order/mall/useProduct',
   editSend: 'order/mall/updateLogistics'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function writeOff(params) {
   return request({
      url: api.writeOff,
      method: 'POST',
      data: params
   })
}

export function editSend(params) {
   return request({
      url: api.editSend,
      method: 'POST',
      data: params
   })
}
