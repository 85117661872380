<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <div class="content-box">
      <el-row class="search-box">
        <el-row class="search-row">
          <el-row class="search-item" v-if="hotelInfo.hotelName != '丰润达优选'">
            <label>券码：</label>
            <el-input v-model="params.couponCode" placeholder="券码"></el-input>
          </el-row>
          <el-row class="search-item">
            <label>订单编号：</label>
            <el-input
              v-model="params.orderNo"
              placeholder="订单编号"
            ></el-input>
          </el-row>
          <el-button
            class="bg-gradient"
            type="primary"
            @click="handleQuery(true)"
            >搜索</el-button
          >
          <el-button type="primary" plain @click="handleQuery(false)"
            >重置</el-button
          >
        </el-row>
      </el-row>
      <el-row class="table-box">
        <el-table
          v-loading="loading"
          :data="tableData"
          :stripe="true"
          border
          fit
        >
          <el-table-column label="序号" width="70">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column
            prop="orderNo"
            label="订单编号"
            width="210"
          ></el-table-column>
          <el-table-column
            prop="couponCode"
            label="券码"
            width="110"
            v-if="hotelInfo.hotelName != '丰润达优选'"
          ></el-table-column>
          <el-table-column prop="name" label="商品名称"></el-table-column>
          <el-table-column
            prop="reserveName"
            label="联系人"
            width="110"
          ></el-table-column>
          <el-table-column
            prop="reserveMobile"
            label="联系电话"
            width="120"
          ></el-table-column>
          <el-table-column
            v-if="hotelInfo.hotelName == '丰润达优选'"
            prop="reserveAddress"
            label="收货地址"
            width="220"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="下单时间"
            width="160"
          ></el-table-column>
          <el-table-column label="订单状态" width="110">
            <template slot-scope="scope">
              {{ scope.row | filterStatus(that) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="srcHotelName"
            label="供应酒店"
          ></el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <div v-if="hotelInfo.hotelName != '丰润达优选'">
                <el-button
                  style="padding: 0"
                  v-if="scope.row.status !== 'CHECKIN'"
                  @click="handleWriteOff(scope.row.id)"
                  type="text"
                >
                  <el-link type="danger">核销</el-link>
                </el-button>
                <span v-else>已核销</span>
              </div>
              <div v-else>
                <el-button
                  style="padding: 0"
                  v-if="!scope.row.logisticsNumber"
                  @click="sendOrder(0, scope.row.id)"
                  type="text"
                >
                  <el-link type="primary">发货</el-link>
                </el-button>
                <el-button
                  style="padding: 0"
                  v-else
                  @click="sendOrder(1, scope.row.id)"
                  type="text"
                >
                  <el-link type="primary">编辑发货信息</el-link>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <pagination
        :total="total"
        :page-size="limit"
        @handleSizeChangeSub="changePageNum"
        @handleCurrentChangeSub="handleCurrPage"
      />
      <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        width="600px"
        :close-on-click-modal="false"
      >
        <el-form>
          <el-form-item label="收货姓名" label-width="100px">
            {{ sendInfo.reserveName }}
          </el-form-item>
          <el-form-item label="联系电话" label-width="100px">
            {{ sendInfo.reserveMobile }}
          </el-form-item>
          <el-form-item label="收货地址" label-width="100px">
            {{ sendInfo.reserveAddress }}
          </el-form-item>
          <el-form-item label="快递公司" label-width="100px">
            <el-input
              v-model="sendInfo.logisticsCompany"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="快递单号" label-width="100px">
            <el-input
              v-model="sendInfo.logisticsNumber"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSend()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { getPage, writeOff, editSend } from "@/api/shop/order/writeOff";
import { getDict } from "@/common/js/common";
export default {
  name: "orderMan",
  data() {
    return {
      crumbs: new Map([["特惠商城"], ["订单中心"], ["订单核销"]]),
      that: this,
      loading: true,
      tableData: [],
      total: 0,
      limit: 0,
      page: 1,
      params: {
        couponCode: "",
        orderNo: "",
      },
      title: "发货", //发货弹出框标题
      dialogFormVisible: false, //发货弹出框显示隐藏
      sendInfo: {},
    };
  },
  computed: {
    ...mapState(["hotelInfo", "dictData"]),
  },
  mounted() {
    getDict("order-status");
    this.limit = sessionStorage.getItem("pageSize");
    this.getOrderList();
  },
  methods: {
    // 获取订单列表
    getOrderList() {
      const query = `?limit=${this.limit}&page=${this.page}`,
        params = {
          ...this.params,
          hotelId: this.hotelInfo.id,
          hotelName: this.hotelInfo.hotelName,
          companyId: this.hotelInfo.storeId,
        };
      // if (this.hotelInfo.hotelName === '达易住特惠') delete params.hotelId
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return;
        this.loading = false;
        this.total = total;
        this.tableData = records;
      });
    },
    // 查询订单
    handleQuery(bool) {
      this.page = 1;
      if (bool) return this.getOrderList();
      for (const k in this.params) this.params[k] = "";
    },
    // 改变每页数
    changePageNum(num) {
      this.limit = num;
      this.getOrderList();
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num;
      this.getOrderList();
    },
    // 处理核销
    handleWriteOff(id) {
        this.$confirm(`确定核销？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((_) => {
          writeOff({ id }).then(({ success }) => {
            if (!success) return;
            this.getOrderList();
            this.$message({ message: "核销成功！", type: "success" });
          });
        });
    },
    handleSend() {
      this.$confirm(`发货信息确认无误？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((_) => {
        if (this.sendInfo.logisticsNumber && this.sendInfo.logisticsCompany) {
          if (this.title == "发货") {
            writeOff(this.sendInfo).then(({ success }) => {
              if (!success) return;
              this.getOrderList();
              this.$message({ message: "发货成功！", type: "success" });
            });
          } else {
            editSend(this.sendInfo).then(({ success }) => {
              if (!success) return;
              this.getOrderList();
              this.$message({ message: "发货信息修改成功！", type: "success" });
            });
          }
          this.dialogFormVisible = false;
        } else {
          this.$message({ message: "发货信息必填", type: "info" });
          return;
        }
      });
    },
    sendOrder(i, id) {
      let order = this.tableData.filter((item) => item.id == id)[0];
      let {
        reserveName,
        reserveMobile,
        reserveAddress,
        logisticsNumber,
        logisticsCompany,
      } = order;
      this.sendInfo = {
        id,
        reserveName,
        reserveMobile,
        reserveAddress,
        logisticsNumber,
        logisticsCompany,
      };
      if (i) {
        this.title = "编辑发货信息";
        this.dialogFormVisible = true;
      } else {
        this.title = "发货";
        this.dialogFormVisible = true;
      }
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      oldVal && newVal.id !== oldVal.id && this.getOrderList();
    },
  },
  filters: {
    filterStatus(val, that) {
      if (that.hotelInfo.hotelName == "丰润达优选") {
        if (val.logisticsNumber) return "已发货";
        else return "未发货";
      } else {
        if (val.status && that.dictData["order-status"]) {
          if (val.status === "CHECKIN") return "已核销";
          if (val.status === "PENDING_PAYMENT") return "待核销";
          else return that.dictData["order-status"][val.status] || val.status;
        }
      }
    },
  },
};
</script>
